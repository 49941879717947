// Icons Horsens

@font-face {
	font-family: 'icomoon-horsens';
	src: url('/assets/fonts/icons-horsens/icomoon.eot?rfzp4p');
	src: url('/assets/fonts/icons-horsens/icomoon.eot?rfzp4p#iefix') format('embedded-opentype'),
		url('/assets/fonts/icons-horsens/icomoon.ttf?rfzp4p') format('truetype'),
		url('/assets/fonts/icons-horsens/icomoon.woff?rfzp4p') format('woff'),
		url('/assets/fonts/icons-horsens/icomoon.svg?rfzp4p#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.link--icon {
	[class^="icon-horsens-"]::before,
	[class*="icon-horsens-"]::before {
    font-family: 'icomoon' !important;
	}
}

[class^="icon-horsens-"],
[class*="icon-horsens-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon-horsens' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: 2rem; // icon size (Initial size - don't change)

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-horsens-aktiviteter:before {
  content: "\e900";
}
.icon-horsens-boernefamilie:before {
  content: "\e901";
}
.icon-horsens-handicap:before {
  content: "\e902";
}
.icon-horsens-hjaelptilhverdag:before {
  content: "\e903";
}
.icon-horsens-it:before {
  content: "\e904";
}
.icon-horsens-snak-sammen:before {
  content: "\e905";
}
.icon-horsens-sundhed:before {
  content: "\e906";
}
.icon-horsens-tanker:before {
  content: "\e907";
}
.icon-horsens-tilflytter:before {
  content: "\e908";
}
.icon-horsens-vaere-sammen:before {
  content: "\e909";
}
.icon-horsens-byggeriogejendom:before {
  content: "\e90a";
}
.icon-horsens-landbrugogvirksomheder:before {
  content: "\e90b";
}
.icon-horsens-lejeboliger:before {
  content: "\e90c";
}
.icon-horsens-naturogmiloeregulering:before {
  content: "\e90d";
}
.icon-horsens-naturogfriluftsliv:before {
  content: "\e90e";
}
.icon-horsens-newtohorsens:before {
  content: "\e90f";
}
.icon-horsens-parkeringvejogtrafik:before {
  content: "\e910";
}
.icon-horsens-affald:before {
  content: "\e911";
}
.icon-horsens-erhvervsaffald:before {
  content: "\e912";
}
.icon-horsens-borgerservice:before {
  content: "\e913";
}
.icon-horsens-aeldre:before {
  content: "\e914";
}
.icon-horsens-misbrugsbehandling:before {
  content: "\e915";
}
.icon-horsens-servicetilvirksomheder:before {
  content: "\e916";
}
.icon-horsens-sportogidraet:before {
  content: "\e917";
}
.icon-horsens-raadognaevn:before {
  content: "\e918";
}
.icon-horsens-udvalg:before {
  content: "\e919";
}
.icon-horsens-boernogunge:before {
  content: "\e91a";
}
.icon-horsens-familie-teen:before {
  content: "\e91b";
}
.icon-horsens-byraadet:before {
  content: "\e91c";
}
.icon-horsens-foreningerogtilladelser:before {
  content: "\e91d";
}
.icon-horsens-handicapogpsykiatri:before {
  content: "\e91e";
}
.icon-horsens-jobogledighed:before {
  content: "\e91f";
}
.icon-horsens-sundhedsfremme:before {
  content: "\e920";
}
.icon-horsens-workinhorsens:before {
  content: "\e921";
}
.icon-horsens-livsfaser:before {
  content: "\e922";
}
.icon-horsens-tid_2:before {
  content: "\e923";
}
.icon-horsens-lokalsektorogkommuneplaner:before {
  content: "\e924";
}
.icon-horsens-pas-koerekort:before {
  content: "\e925";
}
.icon-horsens-erhvervssamarbejder:before {
  content: "\e926";
}
.icon-horsens-hoeringerogafgoerelser:before {
  content: "\e927";
}
.icon-horsens-planerogpolitikker:before {
  content: "\e928";
}
.icon-horsens-studyinhorsens:before {
  content: "\e929";
}
.icon-horsens-uddannelse:before {
  content: "\e92a";
}
.icon-horsens-udviklingsprojekter:before {
  content: "\e92b";
}
.icon-horsens-jobcentretstilbud:before {
  content: "\e92c";
}
.icon-horsens-jobikommunen:before {
  content: "\e92d";
}
.icon-horsens-kontaktogaabningstider:before {
  content: "\e92e";
}
.icon-horsens-foraar:before {
  content: "\e92f";
}
.icon-horsens-sommer:before {
  content: "\e930";
}
.icon-horsens-efterar:before {
  content: "\e931";
}
.icon-horsens-vinter:before {
  content: "\e932";
}
.icon-horsens-corona:before {
  content: "\e982";
}
.icon-horsens-kloakvandogmiljoe:before {
  content: "\e934";
}
.icon-horsens-kulturogevents:before {
  content: "\e935";
}
.icon-horsens-kulturogevents2:before {
  content: "\e936";
}
.icon-horsens-laeger:before {
  content: "\e937";
}
.icon-horsens-sygdom:before {
  content: "\e938";
}
.icon-horsens-oekonomiogstoette:before {
  content: "\e939";
}
.icon-horsens-presse:before {
  content: "\e93a";
}
.icon-horsens-udbud:before {
  content: "\e93b";
}
.icon-horsens-skadedyr:before {
  content: "\e93c";
}
.icon-horsens-faktaomhorsenskommune .path1:before {
  content: "\e93d";
  color: rgb(239, 62, 51);
}
.icon-horsens-faktaomhorsenskommune .path2:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(239, 62, 51);
}
.icon-horsens-faktaomhorsenskommune .path3:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-horsens-faktaomhorsenskommune .path4:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(24, 23, 22);
}
.icon-horsens-faktaomhorsenskommune .path5:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-horsens-faktaomhorsenskommune .path6:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-horsens-faktaomhorsenskommune .path7:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-horsens-faktaomhorsenskommune .path8:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-horsens-faktaomhorsenskommune .path9:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-horsens-faktaomhorsenskommune .path10:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-horsens-faktaomhorsenskommune .path11:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-horsens-speachbubble:before {
  content: "\e948";
}
.icon-horsens-ckt-skaerm:before {
  content: "\e949";
}
.icon-horsens-ckt-thc-45:before {
  content: "\e94a";
}
.icon-horsens-ckt-thc-47 .path1:before {
  content: "\e94b";
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path2:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path3:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path4:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path5:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path6:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path7:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path8:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path9:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path10:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path11:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path12:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path13:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path14:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path15:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path16:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-47 .path17:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-horsens-ckt-thc-47 .path18:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path1:before {
  content: "\e95d";
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path2:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path3:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path4:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path5:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path6:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-horsens-ckt-thc-61 .path7:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path8:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path9:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path10:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path11:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path12:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path13:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path14:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-horsens-ckt-thc-61 .path15:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-horsens-ckt-thc-61 .path16:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path17:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path18:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-horsens-ckt-thc-61 .path19:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-horsens-ckt-thc-61 .path20:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-horsens-ckt-thc-41:before {
  content: "\e971";
}
.icon-horsens-ckt-thc-42:before {
  content: "\e972";
}
.icon-horsens-ckt-thc-43:before {
  content: "\e973";
}
.icon-horsens-ckt-thc-44:before {
  content: "\e974";
}
.icon-horsens-ckt-thc-46:before {
  content: "\e975";
}
.icon-horsens-ckt-thc-48:before {
  content: "\e976";
}
.icon-horsens-ckt-thc-49:before {
  content: "\e977";
}
.icon-horsens-ckt-thc-50:before {
  content: "\e978";
}
.icon-horsens-ckt-thc-51:before {
  content: "\e979";
}
.icon-horsens-ckt-thc-52:before {
  content: "\e97a";
}
.icon-horsens-ckt-thc-53:before {
  content: "\e97b";
}
.icon-horsens-ckt-thc-54:before {
  content: "\e97c";
}
.icon-horsens-ckt-thc-55:before {
  content: "\e97d";
}
.icon-horsens-ckt-thc-57:before {
  content: "\e97e";
}
.icon-horsens-ckt-thc-58:before {
  content: "\e97f";
}
.icon-horsens-ckt-thc-59:before {
  content: "\e980";
}
.icon-horsens-ckt-thc-60:before {
  content: "\e981";
}
